import React, { useEffect } from "react";
import Filter from "../components/filter/Filter";
import Header from "../components/header/Header";
import FrontOfficeLayout from "../components/layouts/FrontOfficeLayout";
import { useState } from "react";
import GridView from "../components/billboards/GridView";
import { useStoreState, useStoreActions, useStoreRehydrated } from "easy-peasy";
import Loader from "../components/loader/Loader";
import cns from "classnames";
//import MapView from "../components/billboards/MapView";

import dynamic from "next/dynamic";
import { getFilteringCombos } from "../utils";
import NoBoardFound from "../components/billboards/NoBoardFound";
const MapView = dynamic(() => import("../components/billboards/MapView"), {
  ssr: false,
  //loading: () => <Loader />,
});

const BillboardsPage = () => {
  const { billboards, isGridView } = useStoreState((state) => state.billboards);
  const { setGridView } = useStoreActions((actions) => actions.billboards);

  //Filter
  const [state, setState] = useState({
    checked: {},
    categoryItems: [],
    dimensionItems: [],
    zoneItems: [],
  });

  const [click, handleClick] = useState(false);
  const [filteredBoards, setFilteredBoards] = useState([]);

  useEffect(() => setFilteredBoards(billboards), []);

  const filterHandler = (e) => {
    handleClick((click) => !click);
    const { name, checked: isBoxChecked } = e.target;
    const { filterType } = e.target.dataset;

    const itemsKey = `${filterType}Items`;

    setState((previousState) => {
      const checked = { ...previousState.checked };
      checked[name] = !checked[name];
      let newList = previousState[itemsKey];

      if (isBoxChecked) {
        newList.push(name);
      } else {
        newList = newList.filter((item) => item !== name);
      }

      return { ...previousState, checked, [itemsKey]: newList };
    });
  };

  useEffect(() => {
    const FilteringCombos = getFilteringCombos(
      {
        type: "category",
        items: state.categoryItems,
      },
      {
        type: "dimension",
        items: state.dimensionItems,
      },
      {
        type: "zone",
        items: state.zoneItems,
      }
    );

    let boards = [];
    FilteringCombos.forEach((f) => {
      boards = [
        ...boards,
        ...billboards.filter((b) => {
          return (
            (b.category.toLowerCase() === f.category || !f.category) &&
            (b.dimension === parseInt(f.dimension) || !f.dimension) &&
            (b.common.toLowerCase() === f.zone || !f.zone)
          );
        }),
      ];
    });

    if (!FilteringCombos.length) boards = billboards;

    setFilteredBoards(boards);
  }, [click]);

  const isStoreRehydrated = useStoreRehydrated();

  useEffect(() => {
    if (billboards.length && !filteredBoards.length) {
      setFilteredBoards(billboards);
    }
  }, [billboards]);

  return (
    <FrontOfficeLayout
      pageTitle="Commandez vos panneaux, planifiez et suivez vos campagnes publicitaires"
      pageDesc="Planifiez en quelques cliques vos campagnes d’affichage sur les panneaux publicitaires géolocalisés sur une carte interactive."
      pageUrl="https://www.regitorr.com"
      pageImg="https://www.regitorr.com/regitor-homepage-img.jpeg"
    >
      <Header>
        <Filter filterHandler={filterHandler} />
      </Header>

      <div className="text-center views-btns d-block">
        <button
          className={cns("btn btn-switch-view", {
            active: isGridView,
            disabled: !filteredBoards.length,
          })}
          onClick={() => setGridView({ isGridView: true })}
        >
          Vue grille
        </button>
        <button
          className={cns("btn btn-switch-view", {
            active: !isGridView,
            disabled: !filteredBoards.length,
          })}
          onClick={() => setGridView({ isGridView: false })}
        >
          Vue sur carte
        </button>
      </div>

      {isStoreRehydrated ? (
        <main className="pb-5 views">
          {!filteredBoards.length && billboards.length ? (
            <NoBoardFound />
          ) : null}
          {filteredBoards.length && isGridView ? (
            <GridView filteredItems={filteredBoards} click={click} />
          ) : filteredBoards.length ? (
            <MapView filteredItems={filteredBoards} />
          ) : null}
        </main>
      ) : <main />}
      <style jsx>{`
        .views-btns {
          /* padding: 20px 10px; */
          /* margin-top: -10px; */
        }

        .btn-switch-view {
          width: 120px;
          padding: 3px 10px;
          border: 2px solid #fbc216;
          color: #fbc216;
          border-radius: 3px;
          transition: 0.4s;
          user-select: none;
          background-color: #fff;
        }

        .btn-switch-view:first-child {
          margin-right: 10px;
        }

        .btn-switch-view:hover:not(.disabled),
        .btn-switch-view:focus:not(.disabled),
        .btn-switch-view.active {
          background-color: #fbc216;
          color: #fff;
          /* box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18),
            0 4px 15px 0 rgba(0, 0, 0, 0.15); */
          box-shadow: none;
        }

        .views {
          width: 90vw;
          max-width: 1400px;
          margin: 0 auto;
          margin-top: 2em;
        }
      `}</style>
    </FrontOfficeLayout>
  );
};

export default BillboardsPage;
